<template>
  <div>
    <div class="title">仓库管理</div>

    <div class="table">
      <el-table :data="tableData" :header-cell-style="{'text-align':'center','font-weight': 'normal','font-size': '12px', 'color': '#333'}" :cell-style="{'text-align':'center','font-weight': 'normal','font-size': '12px','color': '#333'}" style="width: 100%" size="mini">
        <el-table-column v-for="(item, index) in tableHeader" :key="index" :prop="item.prop" :label="item.label" :width="item.width">
          <template slot-scope="scope">
            <div v-if="item.prop !== 'operate'">{{scope.row[item.prop]}}</div>
            <div v-else>
              <div v-for="(item, index) in scope.row.operate" :key="index">
                <div class="btn" @click="operate(scope.row)" v-show="item.show">{{ item.name }}</div>
              </div>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination background layout="total, prev, pager, next" :total="total" @current-change="handleCurrentChange" style="margin-top: 20px;"></el-pagination>
    </div>

  </div>

</template>

<script>
export default {
  data () {
    return {
      tableData: [],
      tableHeader: [],
      total: 0,

      currentPage: 0,
      pageSize: 10

    }
  },
  computed: {
    userInfo () {
      return this.$store.state.UserInfo
    }
  },
  created () {
    this.search()
  },
  methods: {
    search () {
      let params = {
        process_id: '00000212', // 流程ID
        nodeInfo: // 传参  查询则是查询条件  可传可不传等
        {
          wmsId: 'yuncang',
          size: this.pageSize, // 分页页容量（从0开始，-1代表查全部）
          page: this.currentPage // 分页页码（从0开始）
        }
      }
      this.$post('a6c611cc-ded1-4a49-8823-0c66f6ebf7d9', params).then((res) => {
        if (res.succeed === 0) {
          this.tableData = res.data.content
          this.tableData = this.tableData.map(item => {
            if (item.bindStatusDesc !== '待关联') {
              item.operate = item.operate.filter(itemchi => {
                return itemchi.name !== '申请关联'
              })
            }
            return item
          })
          console.log(this.tableData)
          this.tableHeader = res.data.title
          this.total = res.data.total
          // 30分钟轮询接口
          setTimeout(() => {
            this.search()
          }, 60000 * 30)
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    handleCurrentChange (page) {
      this.currentPage = page - 1
      this.search()
    },
    operate (data) {
      if (data.bindStatusDesc === '审核中') {
        let params = {
          process_id: '00000212',
          execution_type: 'get', // get 代表读请求 获取数据,  set  代表写请求 编辑等操作
          nextProcessNode: '002', // 上面接口的  operate.node_id字段的值
          nodeInfo: {

          }
        }
        this.$post('a6c611cc-ded1-4a49-8823-0c66f6ebf7d9', params).then((res) => {
          if (res.succeed === 0) {
            this.$message({
              message: `${res.errorMsg}`,
              type: 'success'
            })
            this.search()
          }
        }).catch((err) => {
          console.log(err)
        })
      } else {
        let params = {
          process_id: '00000212',
          execution_type: 'set', // get 代表读请求 获取数据,  set 代表写请求 编辑等操作
          nextProcessNode: '001', // 上面接口的  operate.node_id字段的值
          nodeInfo: {
            wmsId: 'yuncang', // 仓库管理系统ID（不传的话，默认为yuncang：云仓）
            warehouseId: data.warehouseId, // 仓库ID
            organizationId: this.userInfo.organizationId[0] || '' // 机构ID
          }
        }
        this.$post('a6c611cc-ded1-4a49-8823-0c66f6ebf7d9', params).then((res) => {
          if (res.succeed === 0) {
            this.$message({
              message: `${data.warehouseName}已添加`,
              type: 'success'
            })
            this.search()
          }
        }).catch((err) => {
          console.log(err)
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.title {
  border-bottom: 3px solid #1d74e0;
  height: 35px;
  line-height: 27px;
  margin-top: 15px;
  font-size: 16px;
  font-weight: bold;
  color: #666666;
  padding-left: 30px;
}
.table {
  margin-top: 30px;
}
.btn {
  width: 120px;
  background: #c3214a;
  color: #fff;
  margin: 0 auto;
  cursor: pointer;
}
</style>
